import {configureStore} from '@reduxjs/toolkit'
import rootReducer from './rootReducer'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// const store = configureStore({
//   reducer: rootReducer,
// })
// Configuration object for Redux Persist
const persistConfig = {
  key: 'root', // The key for the persisted data in storage
  storage, // The storage engine to be used (local storage in this case)
}

// Create a persisted reducer using the root reducer and the persist configuration
const persistedReducer = persistReducer(persistConfig, rootReducer)

// Create the Redux store using the persisted reducer
const store = configureStore({
  reducer: persistedReducer, // Use the persisted reducer for the store
})

// Create a persistor object that will manage the persistence of the store
const persistor = persistStore(store)

// Export the persistor and the store for use in the application
export {persistor}
export default store
