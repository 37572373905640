/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {NewPassword} from './components/NewPassword'
import {toAbsoluteUrl} from '../../../_ePulse/helpers'

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => {}
  }, [])

  return (
    <div
      className='d-flex justify-content-between h-100'
      style={{
        // backgroundImage: `url(${toAbsoluteUrl('/media/ePulse/loginBG.png')})`,
        background: 'linear-gradient(269.89deg, #29AAA9 0.09%, #564EA2 99.92%)',
        // backgroundSize: ' 100%',
      }}
    >
      {/* begin::left Content */}
      <div className='w-100 login-banner'>
        <h2>Healthcare made simple</h2>
        <ul>
          <li>Doctors</li>
          <li>Pharmacies</li>
          <li>Scan & Diagnostics Centers</li>
          <li>Consumers</li>
        </ul>
      </div>

      {/* begin::Content */}
      <div className='d-flex h-100 login-section'>
        {/* begin::Logo */}
        {/* <a href='#' className='mb-12'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/ePulse/logo3.png')}
            className='theme-dark-show h-45px'
          />
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/ePulse/logo3.png')}
            className='theme-light-show h-65px'
          ></img>
        </a> */}
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='d-flex flex-column align-items-center justify-content-center w-500px bg-body px-15 pt-15'>
        <a href='#' className='mb-12'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/ePulse/logo3.png')}
            className='theme-dark-show h-45px'
          />
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/ePulse/logo3.png')}
            className='theme-light-show h-65px'
          ></img>
        </a>
          <Outlet />
          <div className='d-flex flex-center flex-column-auto p-10 login-footer'>
        <div className='d-flex align-items-center'>
          <a href='#' className='text-muted text-hover-primary'>
            About
          </a>

          {/* <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a> */}

          <a href='#' className='text-muted text-hover-primary'>
            Contact Us
          </a>
        </div>
      </div>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      {/* <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-semibold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div> */}
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      {/* <Route path='login' element={<Login />} /> */}
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='password' element={<NewPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
