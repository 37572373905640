import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import '../../../../App.css'
import {
  BrowserRouter as Router,
  Route,
  Link,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom'
import {useQuery, gql, useMutation} from '@apollo/client'
import {SENDMOTP_MUTATION} from '../../../GraphQLQueries/Auth'
import useMutationApi from '../../../../hooks/useMutation'
import {ToastMessageContainer} from '../../../../_ePulse/layout/components/ToastMessageContainer'
const initialValues = {
  email: 'admin@example.com',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const navigate = useNavigate()
  const [isloading, setLoading] = useState(false)
  // const [sendOTP, {data, loading, error}] = useMutation(SENDMOTP_MUTATION)
  const {createData, loading: loading2, error, data} = useMutationApi(SENDMOTP_MUTATION)

  useEffect(() => {
    if (data) {
      alert(data.getAdminResetPasswordOtp)
      navigate(`/auth/password`, {
        state: {
          email: formik.values.email,
        },
      })
    }
  }, [data])

  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (
      values
      // , {setStatus, setSubmitting}
    ) => {
      setLoading(true)

      try {
        // await sendOTP({
        //   variables: {
        //     email: values.email,
        //   },
        // })

        await createData({
          email: values.email,
        })
        setLoading(false)
        // navigate(`/auth/password`, {
        //   state: {
        //     email: values.email,
        //   },
        // })
      } catch (error) {
        setHasErrors(true)
        setLoading(false)
      }
    },
  })

  return (
    <>
      <ToastMessageContainer />
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Forgot Password</h1>
          {/* end::Title */}

          {/* begin::Link */}
          {/* <div className='text-gray-400 fw-bold fs-4'>No worries, we’ll send you reset instructions</div> */}
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {/* {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{error?.message}</div>
          </div>
        )} */}

        {/* {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )} */}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-column justify-content-center align-items-center '>
          <button type='submit' id='kt_password_reset_submit' className='ePulse-btn-primary'>
            <span className='indicator-label text-white'>Reset Password</span>
            {isloading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>

          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='mt-3 ePulse-btn-secondory '
            disabled={formik.isSubmitting || !formik.isValid}
          >
            <Link to='/auth' className='indicator-label text-dark'>
              Go back to login
            </Link>
          </button>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
