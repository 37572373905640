import {createHttpLink, ApolloProvider, ApolloClient, InMemoryCache, from} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
import {onError} from '@apollo/client/link/error'
import {Suspense} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_ePulse/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_ePulse/layout/core'
import {MasterInit} from '../_ePulse/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {toast} from 'react-toastify'
import {actionsUsers} from '../setup/redux/rootReducer'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
// Variable to hold user data
let dataUser: any = ''

// Function to check if the user has a specific permission
export const checkPermission = (value: any) => {
  const allowed = dataUser?.auth?.userPermissions.includes(value)
  return allowed // Return true if the user has permission, otherwise false

  // return true
}

const App = () => {
  // Fetch user data from Redux store
  dataUser = useSelector((state) => {
    return state
  })

  // const client = new ApolloClient({
  //   uri: 'https://dev-api.myepulse.in/trulyGraphql',
  //   cache: new InMemoryCache(),
  // })
  // Create an HTTP link for Apollo Client
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URI, // API URI from environment variables
  })
  // Set up authentication token in headers for Apollo Client requests
  const authLink = setContext((_, {headers}) => {
    // Get the authentication token from wherever you have it stored

    // Return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: dataUser?.auth?.token ? `Bearer ${dataUser.auth.token}` : null,
        systemName: 'web',
      },
    }
  })
  const dispatch = useDispatch() // Get the Redux dispatch function
  const sessionExpirePattern = /session\s*expire/i // Regex pattern to detect session expiration
  // Handle GraphQL and network errors
  const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({message, locations, path}) => {
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        toast.error(message) // Show error message as a toast notification
        if (sessionExpirePattern.test(message)) {
          // If session expired, log out the user after a delay
          setTimeout(() => {
            dispatch(actionsUsers.logout())
          }, 3000)
        }
      })
    }

    if (networkError) {
      // Log network errors to the console
      console.error(`[Network error]: ${networkError}`)
    }
  })
  // Initialize Apollo Client with error handling and authentication
  const client = new ApolloClient({
    // link: authLink.concat(httpLink),
    link: from([errorLink, authLink.concat(httpLink)]), // Combine error link and auth link
    cache: new InMemoryCache(), // Use in-memory cache for Apollo Client
  })

  return (
    <ApolloProvider client={client}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </ApolloProvider>
  )
}

export {App}
