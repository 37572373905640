import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import '../../../../App.css'
import {useQuery, gql, useMutation} from '@apollo/client'
import {VERIFY_OTP_FORGOT_PASSWORD_MUTATION} from '../../../GraphQLQueries/Auth'
import useMutationApi from '../../../../hooks/useMutation'
import {ToastMessageContainer} from '../../../../_ePulse/layout/components/ToastMessageContainer'
import {toast} from 'react-toastify'
const initialValues = {
  otp: '',
  password: '',
  confirmPassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  otp: Yup.string().required('OTP is required'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string().required('Confirm Password is required'),
})

export function NewPassword() {
  const navigate = useNavigate()
  const [isloading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [hasErrorsmsg, setHasErrorsmsg] = useState<any>('')
  const location = useLocation()
  const {email}: any = location.state

  // const [verifyOTP, {data, loading, error}] = useMutation(VERIFY_OTP_FORGOT_PASSWORD_MUTATION)

  const {createData, loading, error, data} = useMutationApi(VERIFY_OTP_FORGOT_PASSWORD_MUTATION)

  useEffect(() => {
    if (data) {
      navigate(`/auth`)
    }
  }, [data])

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      if (values.password !== values.confirmPassword) {
        // setHasErrors(true)
        toast.error('Password and Confirm Password are not same.')
        setHasErrorsmsg('Password & Confirm Password are not same')
        setLoading(false)
      } else {
        try {
          // await verifyOTP({
          //   variables: {
          //     email: email,
          //     otp: parseInt(values.otp),
          //     newPassword: values.password,
          //   },
          // })

          await createData({
            email: email,
            otp: parseInt(values.otp),
            newPassword: values.password,
          })
          setLoading(false)
          navigate(`/auth`)
        } catch (error: any) {
          console.log('ERROR......', error)
          setHasErrorsmsg(error?.message)
          // setHasErrors(true)
          setLoading(false)
        }
      }
    },
  })

  return (
    <>
      <ToastMessageContainer />
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Enter OTP & New Password</h1>
          {/* end::Title */}

          {/* begin::Link */}
          {/* <div className='text-gray-400 fw-bold fs-4'>No worries, we’ll send you reset instructions</div> */}
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {/* {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{hasErrorsmsg}</div>
          </div>
        )} */}

        {/* {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>{hasErrorsmsg}</div>
          </div>
        )} */}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>OTP</label>
          <input
            placeholder=''
            maxLength={6}
            {...formik.getFieldProps('otp')}
            className={clsx('form-control form-control-lg form-control-solid')}
          />
          {formik.touched.otp && formik.errors.otp && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.otp}</span>
              </div>
            </div>
          )}
          <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
          <input
            type='password'
            placeholder=''
            {...formik.getFieldProps('password')}
            className={clsx('form-control form-control-lg form-control-solid')}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
          <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
          <input
            type='password'
            placeholder=''
            {...formik.getFieldProps('confirmPassword')}
            className={clsx('form-control form-control-lg form-control-solid')}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}

        <div className='d-flex flex-column justify-content-center align-items-center '>
          <button type='submit' id='kt_password_reset_submit' className='ePulse-btn-primary'>
            <span className='indicator-label text-white'>Update Password</span>
            {isloading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>

          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='mt-3 ePulse-btn-secondory'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            <Link to='/auth' className='indicator-label text-dark'>
              Go back to login
            </Link>
          </button>
        </div>

        {/* end::Form group */}
      </form>
    </>
  )
}
