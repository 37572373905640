import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_ePulse/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_ePulse/assets/ts/_utils'
import {WithChildren} from '../../_ePulse/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {UpdatePassword} from '../modules/auth/components/UpdatePassword'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const CalendarPage = lazy(() => import('../modules/calendar/CalendarPage'))
  // const SettingsPage = lazy(() => import('../modules/settings/SettingsPage'))
  const AdminPage = lazy(() => import('../modules/admin/admin/AdminPage'))
  const ClinicsPage = lazy(() => import('../modules/clinic/ClinicPage'))
  const NonApprovedDoctorPage = lazy(
    () => import('../modules/doctor/nonApprovedDoctor/NonApprovedDoctorPage')
  )
  const ActiveUsersPage = lazy(() => import('../modules/users/UsersPage'))
  const PlanPage = lazy(() => import('../modules/plans/plan/PlanPage'))
  const ServicePage = lazy(() => import('../modules/plans/service/ServicePage'))
  const AddOnServicePage = lazy(() => import('../modules/plans/addOnService/AddOnServicePage'))
  const ServiceToPlanPage = lazy(() => import('../modules/plans/serviceToPlan/ServiceToPlanPage'))
  const PlanFrequencyPage = lazy(() => import('../modules/plans/planFrequency/PlanFrequencyPage'))
  // const BillingPricingPage = lazy(() => import('../modules/billingPricing/BillingPricingPage'))
  const RxMedicinePage = lazy(() => import('../modules/doctor/rxMedicine/RxMedicinePage'))
  const DiseasePage = lazy(() => import('../modules/doctor/disease/DiseasePage'))
  const SymptomPage = lazy(() => import('../modules/doctor/symptom/SymptomPage'))
  const TestRequestedPage = lazy(() => import('../modules/doctor/testRequested/TestRequestedPage'))
  const SuperAdminRolePage = lazy(
    () => import('../modules/admin/superAdminRoles/SuperAdminRolePage')
  )

  const ErrorLoggingPage = lazy(() => import('../modules/admin/ErrorLogging/ErrorLoggingPage'))
  const WesiteEnquiryPage = lazy(() => import('../modules/admin/WebsiteEnquiry/WebsiteEnquiryPage'))
  const SpecialityPage = lazy(
    () => import('../modules/doctor/doctorSpeciality/DoctorSpecialityPage')
  )
  const StateCouncilPage = lazy(() => import('../modules/doctor/stateCouncil/StateCouncilPage'))
  const ApplicationPoliciesPage = lazy(
    () => import('../modules/settingsMain/applicationPolicies/PoliciesPage')
  )
  // const ApplicationSettingsPage = lazy(
  //   () => import('../modules/settingsMain/applicationSettings/AppSettingsPage')
  // )
  const ApplicationSettingsPage = lazy(
    () => import('../modules/settingsMain/appSettings/AppSettingsPage')
  )

  const ApprovalbusinessStepsPage = lazy(
    () => import('../modules/settingsMain/approvalbusinessSteps/ApprovalBusinessStepPage')
  )
  const NotificationPage = lazy(
    () => import('../modules/settingsMain/notification/NotificationPage')
  )
  const HolidaysSettingsPage = lazy(
    () => import('../modules/settingsMain/holidaysSettings/HolidaySettingsPage')
  )
  const RolesPermissionsPage = lazy(
    () => import('../modules/admin/RolePermission/RolePermissionPage')
  )

  const AdvicePage = lazy(() => import('../modules/doctor/advice/AdvicePage'))

  const SystemModulePage = lazy(() => import('../modules/admin/systemModules/SystemModulePage'))
  const SystemRolePage = lazy(() => import('../modules/admin/systemRoles/SystemRolePage'))
  const RXPage = lazy(() => import('../modules/rx/RXPage'))

  const SummaryPage = lazy(() => import('../modules/transaction/summary/SummaryPage'))
  const PaymentPage = lazy(() => import('../modules/transaction/payment/PaymentPage'))
  const SettlementsPage = lazy(() => import('../modules/transaction/settlements/SettlementsPage'))
  const TransactionsPage = lazy(
    () => import('../modules/transaction/transactions/transactionsPage')
  )
  const RefundsPage = lazy(() => import('../modules/transaction/refunds/RefundsPage'))
  const FeesSettingPage = lazy(() => import('../modules/transaction/feesSetting/feesSettingPage'))
  const FailedLoginHistoryPage = lazy(
    () => import('../modules/admin/FailedLoginHistory/failedloginHistoryPage')
  )
  const SessionHistoryPage = lazy(
    () => import('../modules/admin/SessionHistory/sessionHistoryPage')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} /> */}
        <Route path='updatePassword' element={<UpdatePassword />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* NEW ROUTES */}
        <Route
          path='calendar/*'
          element={
            <SuspensedView>
              <CalendarPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='settings/*'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='admin/*'
          element={
            <SuspensedView>
              <AdminPage />
            </SuspensedView>
          }
        />
        <Route
          path='business/*'
          element={
            <SuspensedView>
              <ClinicsPage />
            </SuspensedView>
          }
        />
        <Route
          path='users/*'
          element={
            <SuspensedView>
              <ActiveUsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='plan/*'
          element={
            <SuspensedView>
              <PlanPage />
            </SuspensedView>
          }
        />
        <Route
          path='service/*'
          element={
            <SuspensedView>
              <ServicePage />
            </SuspensedView>
          }
        />
        <Route
          path='addon_service/*'
          element={
            <SuspensedView>
              <AddOnServicePage />
            </SuspensedView>
          }
        />
        <Route
          path='servicetoplan/*'
          element={
            <SuspensedView>
              <ServiceToPlanPage />
            </SuspensedView>
          }
        />
        <Route
          path='planfrequency/*'
          element={
            <SuspensedView>
              <PlanFrequencyPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='billing_pricing/*'
          element={
            <SuspensedView>
              <BillingPricingPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='rx-medicine/*'
          element={
            <SuspensedView>
              <RxMedicinePage />
            </SuspensedView>
          }
        />
        <Route
          path='disease/*'
          element={
            <SuspensedView>
              <DiseasePage />
            </SuspensedView>
          }
        />
        <Route
          path='test_requested/*'
          element={
            <SuspensedView>
              <TestRequestedPage />
            </SuspensedView>
          }
        />
        <Route
          path='advice/*'
          element={
            <SuspensedView>
              <AdvicePage />
            </SuspensedView>
          }
        />
        <Route
          path='symptom/*'
          element={
            <SuspensedView>
              <SymptomPage />
            </SuspensedView>
          }
        />
        <Route
          path='error_logging/*'
          element={
            <SuspensedView>
              <ErrorLoggingPage />
            </SuspensedView>
          }
        />
        <Route
          path='website_enquiry/*'
          element={
            <SuspensedView>
              <WesiteEnquiryPage />
            </SuspensedView>
          }
        />
        {/* SETTINGS ROUTES START*/}
        <Route
          path='super_admin_role/*'
          element={
            <SuspensedView>
              <SuperAdminRolePage />
            </SuspensedView>
          }
        />
        <Route
          path='doctor_speciality/*'
          element={
            <SuspensedView>
              <SpecialityPage />
            </SuspensedView>
          }
        />
        <Route
          path='state_council/*'
          element={
            <SuspensedView>
              <StateCouncilPage />
            </SuspensedView>
          }
        />
        <Route
          path='application_setting/*'
          element={
            <SuspensedView>
              <ApplicationSettingsPage />
            </SuspensedView>
          }
        />
        <Route
          path='application_policy/*'
          element={
            <SuspensedView>
              <ApplicationPoliciesPage />
            </SuspensedView>
          }
        />
        <Route
          path='holiday_settings/*'
          element={
            <SuspensedView>
              <HolidaysSettingsPage />
            </SuspensedView>
          }
        />
        <Route
          path='approval_business_steps/*'
          element={
            <SuspensedView>
              <ApprovalbusinessStepsPage />
            </SuspensedView>
          }
        />
        <Route
          path='roles_permissions/*'
          element={
            <SuspensedView>
              <RolesPermissionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='system_module/*'
          element={
            <SuspensedView>
              <SystemModulePage />
            </SuspensedView>
          }
        />
        <Route
          path='system_role/*'
          element={
            <SuspensedView>
              <SystemRolePage />
            </SuspensedView>
          }
        />
        <Route
          path='notification_setting/*'
          element={
            <SuspensedView>
              <NotificationPage />
            </SuspensedView>
          }
        />
        <Route
          path='rx_module/*'
          element={
            <SuspensedView>
              <RXPage />
            </SuspensedView>
          }
        />
        <Route
          path='non_approved_doctor/*'
          element={
            <SuspensedView>
              <NonApprovedDoctorPage />
            </SuspensedView>
          }
        />
        {/* SETTINGS ROUTES END */}
        {/* Transaction Routes Start */}
        <Route
          path='summary/*'
          element={
            <SuspensedView>
              <SummaryPage />
            </SuspensedView>
          }
        />

        <Route
          path='payments/*'
          element={
            <SuspensedView>
              <PaymentPage />
            </SuspensedView>
          }
        />
        <Route
          path='refunds/*'
          element={
            <SuspensedView>
              <RefundsPage />
            </SuspensedView>
          }
        />
        <Route
          path='dispute/*'
          element={
            <SuspensedView>
              <NonApprovedDoctorPage />
            </SuspensedView>
          }
        />

        <Route
          path='transactions/*'
          element={
            <SuspensedView>
              <TransactionsPage />
            </SuspensedView>
          }
        />

        <Route
          path='settlements/*'
          element={
            <SuspensedView>
              <SettlementsPage />
            </SuspensedView>
          }
        />
        <Route
          path='fees-setting/*'
          element={
            <SuspensedView>
              <FeesSettingPage />
            </SuspensedView>
          }
        />

        <Route
          path='failed-login-history/*'
          element={
            <SuspensedView>
              <FailedLoginHistoryPage />
            </SuspensedView>
          }
        />

        <Route
          path='session-history/*'
          element={
            <SuspensedView>
              <SessionHistoryPage />
            </SuspensedView>
          }
        />

        {/* Transaction Routes End */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
