// useMutationApi.js

import { useMutation } from '@apollo/client'
import { gql } from 'graphql-tag'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { actionsUsers } from '../setup/redux/rootReducer'

// Custom hook to handle GraphQL mutations with error handling and notifications
const useMutationApi = (mutation: any) => {
  const dispatch = useDispatch()
  const [createDataMutation, { loading, error, data }]: any = useMutation(mutation)
 
 
  // Function to execute the mutation with the provided input
  const createData = async (input: any) => {
    try {
      const inputVariables = input
      const response = await createDataMutation({
        variables: input,
      })

      // Do something with the response if needed

      return response
    } catch (error: any) {
      // Handle error
      if (error) {

        const sessionExpirePattern = /session\s*expire/i
        // if (sessionExpirePattern.test(error?.message)) {
        //   toast.error('Session Expired! Please login again.')
        //   setTimeout(() => {
        //     dispatch(actionsUsers.logout())
        //   }, 3000)
        // } else {
        //   toast.error(`${error?.message}`)
        // }

        // if (sessionExpirePattern.test(error?.networkError?.result?.errors[0]?.message)) {
        //   toast.error('Session Expired! Please login again.')
        //   setTimeout(() => {
        //     dispatch(actionsUsers.logout())
        //   }, 3000)
        // }
        //  else if (error.networkError.statusCode === 500) {
        //   toast.error(error?.networkError?.result?.errors[0]?.message)
        // }
        //  else {
        //   toast.error(`${error?.message}`)
        // }

        // if (error?.networkError?.statusCode === 440) {
        //   toast.error(`${error?.networkError?.result?.errors[0]?.message}`)
        //   setTimeout(() => {
        //     dispatch(actionsUsers.logout())
        //   }, 3000)
        // } else {
        //   toast.error(`${error?.message}`)
        // }
      }
      throw error
    }
  }

  return {
    createData,
    loading,
    error,
    data,
  }
}

export default useMutationApi
